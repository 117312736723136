<template>
  <UFooter>
    <template #left>
      <p class="text-gray-500 dark:text-gray-400 text-sm">
        Copyright © {{ new Date().getFullYear() }}. All rights reserved.
      </p>
    </template>

    <template #right>
      <span class="text-gray-500 dark:text-gray-400 text-sm">
        Developed by
      </span><a
        href="https://kaweah.tech"
        target="_blank"
        aria-label="Go to the Kaweah Tech website in a new window"
      >
        <img
          class="object-scale-down h-4"
          alt="Developed by Kaweah Tech"
          src="https://assets.kaweah.tech/kaweah-tech-logo-lite.svg"
        >
      </a>
    </template>
  </UFooter>
</template>
