<script setup lang="ts">
const { data: headerContent } = await useAsyncData('content:components/header', () => queryContent('components/header').findOne())
</script>

<template>
  <UHeader :links="headerContent?.links">
    <template #logo>
      <div class="font-smoke font-normal tracking-normal text-primary-200 text-outline text-2xl sm:text-3xl lg:text-2xl">
        {{ headerContent?.logo.text }}
      </div>
    </template>

    <template #right>
      <UButton
        :label="headerContent?.phone.label"
        :leading-icon="headerContent?.phone.icon"
        :to="headerContent?.phone.to"
        color="primary"
        variant="link"
        class="hidden lg:flex"
      />
    </template>

    <template #panel>
      <UNavigationTree :links="headerContent?.links"/>

      <UDivider class="my-6"/>

      <UButton
        :label="headerContent?.phone.label"
        :leading-icon="headerContent?.phone.icon"
        :to="headerContent?.phone.to"
        block
        class="mb-3"
      />
    </template>
  </UHeader>
</template>
